import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import reactDom from 'react-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(1),
  },
  marginBottom: 0
}));

const marks = [
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100',
  },
];

function valuetext(value) {
  return `${value}`;
}


export function ZoomInAgeSlider(userInput , setUserInput) {
  
  const classes = useStyles()

  const [sliderValue, setSliderValue] = React.useState(userInput.zoomedInAge)

  const commitUpdate = (v) => {
    setSliderValue(v)
    setUserInput({...userInput, zoomedInAge: v})
  }


  let slider = <Slider
        defaultValue={60}
        value={sliderValue}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        min={userInput.currentAge}
        max={userInput.lifeExpectancy}
        step={1}
        marks={marks}
        valueLabelDisplay="on"
        onChange={(e , v) => commitUpdate(v)}
        // onChangeCommitted={(e,v) => commitUpdate(v)}
      />

  return (
    <div className={classes.root}>
      {slider}
    </div>
  );
}






export function JumpOffAgeSlider(userInput, setUserInput ) {

  const classes = useStyles();

  const commitUpdate = (v) => {
    setUserInput({...userInput, jumpOffAge: v})
  }


  const [sliderValue, setSliderValue] = useState(userInput.jumpOffAge)


  let slider = <Slider
        value={sliderValue}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        min={userInput.currentAge}
        max={userInput.lifeExpectancy}
        step={1}
        marks={marks}
        valueLabelDisplay="on"
        onChange={(e , v) => setSliderValue(v)}
        onChangeCommitted={(e,v) => commitUpdate(v)}
      />

  return (
    <div className={classes.root}>
      {slider}
    </div>
  );
}


export const AgeSlider = (userInput, setUserInput) => {
  const classes = useStyles();

  const [sliderValue , setSliderValue] = React.useState(userInput.currentAge)

  const commitValue = (v) => {
    setUserInput({...userInput, currentAge: v})
  }


  let slider = <Slider

        value={sliderValue}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        step={1}
        max = {userInput.lifeExpectancy}
        marks={marks}
        valueLabelDisplay="on"
        onChange={(e , v) => setSliderValue(v)} 
        onChangeCommitted={(e,v) => commitValue(v)}
      />

  return (
    <div className={classes.root}>
      {slider}
    </div>
  );
}

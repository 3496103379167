import ReactGA from "react-ga";

export function exportToCsv(filename, rows) {
  var processRow = function(row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const LogGAEvent = (category, action) => {
  ReactGA.event({ category: category, action: action });
};

//https://stackoverflow.com/questions/36734201/how-to-convert-numbers-to-million-in-javascript/36734774
export const ValueFormatter = (value) => {
  let labelValue = Math.floor(value);

  // Nine Zeroes for Billions
  return Math.floor(Number(labelValue)) >= 1.0e9
    ? // ? Math.floor((Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
      (Number(labelValue) / 1.0e9).toFixed(1) + "B"
    : // Six Zeroes for Millions
    Math.floor(Number(labelValue)) >= 1.0e6
    ? Math.floor(Number(labelValue) / 1.0e6) + "M"
    : // Three Zeroes for Thousands
    Math.floor(Number(labelValue)) >= 1.0e3
    ? Math.floor(Number(labelValue) / 1.0e3) + "K"
    : Math.floor(Number(labelValue));
};

// https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

Date.prototype.formatMMDDYYYY = function() {
  return this.getMonth() + 1 + "/" + this.getDate() + "/" + this.getFullYear();
};

// https://blog.bitsrc.io/how-to-use-the-web-share-api-99460be88ad8
export const ShareApp = () => {
  if (navigator.share) {
    navigator
      .share({
        title: "Title Share this thing baby",
        text: "text goes here",
        url: "www.finculator.app",
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  } else {
    console.log("Web Share API is not supported in your browser.");
  }
};

export const GetDateString = () => {
  const d = new Date();
  const mm = d.getMonth() + 1;
  const dd = d.getDate();
  const yy = d.getFullYear();
  const dateString = yy + "-" + mm + "-" + dd;
  return dateString;
};

export const GetTimeString = () => {
  const d = new Date();
  const hh = d.getHours();
  const mn = d.getMinutes();
  const ss = d.getSeconds();

  const timeString = hh + ":" + mn;
  return timeString;
};

// https://json.geoiplookup.io/
// https://ipapi.co/json/
// https://ipinfo.io/json

var firstRunComplete = false;

export const GetUserIPDataV2 = (userInput, setUserInput) => {
  // fetchErrors();

  if (userInput.userLocation === "notSet" || userInput.userLocation === null) {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((response) => {
        console.log("response -: ", response);
        const locationString =
          response.country + " ⌾ " + response.region + " ⌾ " + response.city;
        setUserInput({ ...userInput, userLocation: locationString });
        firstRunComplete = true;
      });
  }
};

// experiment on errors
const fetchErrors = () => {
  fetch("https://ipapi.co/json/")
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong");
      }
    })
    .then((responseJson) => {
      // Do something with the response
      // console.log(responseJson);
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const GetUserIPData = (userInput, setUserInput) => {
  GetUserIPDataV2(userInput, setUserInput);
  return;
  // if (!userIPDataResponse) {
  //   fetch("https://ipapi.co/json/")
  //     .then((response) => response.json())
  //     .then((response) => {
  //       userIPDataResponse = response;

  //       const locationString =
  //         response.country_code_iso3 +
  //         " ⌾ " +
  //         response.region_code +
  //         " ⌾ " +
  //         response.city +
  //         " ⌾ " +
  //         response.postal;
  //       setUserInput({ ...userInput, userLocation: locationString });
  //     });
  //  }
};

var initialRunComplete = false;
var messages = [
  "When does work become optional?",
  "When does the grind end?",
  "When can my money support me?",
];
var tagline = "";
//
export const TitleMessage = () => {
  if (!initialRunComplete) {
    initialRunComplete = true;
    let rand = randomIntFromInterval(0, messages.length - 1);
    tagline = messages[rand];
  }
  return tagline;
};

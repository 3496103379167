import { Container, Typography } from "@material-ui/core";

export const CustomCallOut = (callOutText) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Container
        style={{
          backgroundColor: "#ECEFF1",
          paddingBottom: 5,
          paddingLeft: 5,
          paddingRight: 5,
          minHeight: 20,
        }}
      >
        <Typography
          variant="caption"
          style={{ lineHeight: "normal", fontStyle: "italic" }}
        >
          {callOutText}
        </Typography>
      </Container>
    </div>
  );
};

import "./App.css";

import { React, useState, useEffect } from "react";
import NormalDistribution from "normal-distribution"; // Works for looking up percentiles
import norminv from "./NormInv"; // Works but need to proove out
import createMemberInNormalDistribution from "./BoxMueller"; // Works but need to proove out
import inv from "./NormInv";

import "@toast-ui/chart/dist/toastui-chart.min.css";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  InflationRatesV2,
  PerformanceRatesV2,
} from "./Components/InvestmentStyles";
import { NowCard } from "./Components/InputCards/NowCard";
import { PostRetirementCard } from "./Components/InputCards/PostRetirementCard";
import { WhileYouWorkCard } from "./Components/InputCards/WhileYouWorkCard";
import { MacroConditionsCard } from "./Components/InputCards/MacroConditionsCard";

import { ZeroBalProbabilitiesChart } from "./Components/Charts/ZeroProbabilities";
import { HistogramBarChart } from "./Components/Charts/HistogramBarChart";
import { GetUserIPData, TitleMessage } from "./Components/Utilities";
import CustomizedStepper from "./Components/Stepper";
import { SetUpFirebase, WriteUserDataToFirebase } from "./Components/Firebase";
import { ReactComponent as LogoSVG } from "./Assets/Logo.svg";
import CustomizedRatings from "./Components/Rating";
import { Footer } from "./Components/Footer";

function App() {
  const [userInput, setUserInput] = useState({
    currentIRA: 10000,
    currentTaxed: 20000,
    currentAge: 33,
    jumpOffAge: 60,
    IRAContribution: 10000,
    taxedContribution: 5000,
    lifeExpectancy: 100,
    desiredDistribution: 40000,
    distributionGrowth: 0,
    inflation: InflationRatesV2.last30,
    investmentStyle: PerformanceRatesV2.benchmarks.SNP500,
    nerdModeOn: false,
    zoomedInAge: 100,
    inputStage: 0,
    userID: "null",
    userLocation: "notSet",
  });

  GetUserIPData(userInput, setUserInput);
  SetUpFirebase(userInput, setUserInput);

  useEffect(() => {
    // console.log("user input: ", userInput);
    WriteUserDataToFirebase(userInput);
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.primary,
    },
  }));

  const topCards = [
    NowCard(userInput, setUserInput),
    WhileYouWorkCard(userInput, setUserInput),
    PostRetirementCard(userInput, setUserInput),
    MacroConditionsCard(userInput, setUserInput),
  ];

  return (
    <div className={useStyles().root} style={{ backgroundColor: "#f5f5f5" }}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            paddingBottom: 0,
            paddingTop: 0,
            height: "1%",
          }}
        >
          <br />
          <LogoSVG style={{ width: 150, height: "30%" }} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h4> {TitleMessage()} </h4>
        </Grid>
        <Grid item xs={12}>
          {topCards[userInput.inputStage]}
        </Grid>
        <Container>
          {CustomizedStepper(userInput, setUserInput, topCards)}
        </Container>
        {ZeroBalProbabilitiesChart(userInput, setUserInput)}
        {HistogramBarChart(userInput, setUserInput)}
        <br />
        ,
        <br />
        {CustomizedRatings(userInput, setUserInput)}
        {Footer()}
      </Grid>
    </div>
  );
}

export default App;

import React from "react";
import inv from "../NormInv";
import getTax from "./GetTax";
import { NowCard } from "./InputCards/NowCard";
import { PerformanceRates, PerformanceRatesV2 } from "./InvestmentStyles";
import {exportToJsonFile} from "./RunStats";
import { exportToCsv } from "./Utilities";




export function getReturnRateV2(userInput) {
  const {investmentStyle, inflation} = userInput
  
    let horizon = inflation.rawValue
    let cagr = investmentStyle[horizon]["cagr"]
    let mean = investmentStyle[horizon]["mean"]
    let stDev = investmentStyle[horizon]["stDev"]

  return inv(Math.random(),mean, stDev);

}




var lastUserInput = {}
var lastOutput = {}
  
export function simulateMarketPerf(userInput, xTimes, debugMessage) {


  const controlUserInput = Object.assign({}, userInput)
  delete controlUserInput.nerdModeOn
  delete controlUserInput.zoomedInAge
  delete controlUserInput.inputStage
  delete controlUserInput.userID

  

    if (JSON.stringify(controlUserInput) === JSON.stringify(lastUserInput)) {
      return lastOutput
    }
    // console.log("entered data simulations")
    // console.log("debug message: ", debugMessage)
  
  const {
    currentIRA, 
    currentTaxed, 
    currentAge,
    jumpOffAge,
    IRAContribution,
    taxedContribution,
    lifeExpectancy,
    desiredDistribution, 
    distributionGrowth, 
    inflation,
    investmentStyle } = userInput

  

  let simulatedLives = []
  let analyticExport = [["age","iraGrowth","iraContribution","iraWithdrawal","iraTax", "iraEndBal", "taxedGrowth", "taxedContribution", "taxedWithdrawal", "taxedTax", "taxedEndBal"]]
  let ageYears = []
  let zeroBals = {}

  let firstLoopComplete = false

  var startTime = new Date().getTime()
    
  for (let i = 1; i <= xTimes; i++) {
    let simulatedLife = []
    let runningBal = {
      ira: currentIRA,
      taxed: currentTaxed,
    }
    
    
    for (let a = currentAge; a <= lifeExpectancy; a++) {
      let yearsOffset = a - currentAge
      function presentValueOf(fv) { return (fv / ((1+inflation.value)**yearsOffset)) }
      function futureValueOf(pv) { return (pv * ((1+inflation.value)**yearsOffset)) }
  

      // ----- Growth
      let growthRate = getReturnRateV2(userInput)
      let IRAgrowth = runningBal.ira * growthRate
      let taxedGrowth = runningBal.taxed * growthRate


      // ----- Contributions
      let iraContr = 0, taxedContr = 0
      if (a < jumpOffAge) {
        iraContr = IRAContribution
        taxedContr = taxedContribution
      } 


      // ----- Desired distributions
      let distributionFV = 0 
      let distributionPV = () => { return presentValueOf(distributionFV) }
      if (a >= jumpOffAge) {
        distributionFV = desiredDistribution * ((1+inflation.value+distributionGrowth)**(yearsOffset))
      }

      // ----- Distribution + Taxes + New Bal
      let iraDistr = 0
      let iraTax = 0
      let iraNewBal = () => {return Math.max(0, (runningBal.ira + IRAgrowth + iraContr - iraDistr - iraTax))}
      let taxedDistr = 0
      let taxedTax = 0
      let taxedNewBal = () => {return Math.max(0,(runningBal.taxed + taxedGrowth + taxedContr - taxedDistr - taxedTax))} 



      if (!firstLoopComplete) {
        ageYears.push(a)
      }
      if (iraNewBal() === 0) {
        zeroBals[a] = (zeroBals[a] || 1) +1
      } 


        // After jump-off age
        // If sufficient balance draw from taxed account first
        // If sufficient balance draw from IRA next
      if (a >= jumpOffAge) {
        if (runningBal.taxed > distributionFV * 1.3) {
          taxedDistr = distributionFV
          taxedTax = futureValueOf(getTax(distributionPV(), false))
        } else {
          iraDistr = distributionFV
          iraTax = futureValueOf(getTax(distributionPV(), true))
        }
      } 

      analyticExport.push([a, IRAgrowth, iraContr, iraDistr, iraTax, iraNewBal(), taxedGrowth,taxedContr, taxedDistr, taxedTax, taxedNewBal()])
      
      let simYear = {
        age: a,
        ira: {
          growth: IRAgrowth,
          contribution: iraContr,
          withdrawal: iraDistr,
          tax: iraTax,
          endBalance: iraNewBal()
        },
        taxed: {
          growth: taxedGrowth,
          contribution: taxedContr,
          withdrawal: taxedDistr,
          tax: taxedTax,
          endBalance: taxedNewBal()
        }
      }
      simulatedLife.push(simYear)


      runningBal.ira = iraNewBal()
      runningBal.taxed = taxedNewBal()
    
    }   /// ====== END OF Life Loop =======

    
    simulatedLives.push(simulatedLife)
    firstLoopComplete = true
    
  }   /// ==== END OF ALL Sims LOOP =======

  endTimePop(startTime, "simulation run time:")

  // exportToJsonFile(analyticExport)
    // exportToCsv("market sim data".csv,analyticExport)
    // console.log("age years:" , ageYears)
    // console.log("zero bals:" , zeroBals)
    

    let zeroBalProbabilities = []
    for (let i = 0; i < ageYears.length; i++) {
      const element = ageYears[i];
      if (zeroBals[element]) {
          let zeroBalProbability = (1 - (zeroBals[element] / xTimes)) * 100
          zeroBalProbabilities.push(zeroBalProbability)
        } else {
          zeroBalProbabilities.push(100)
        }
    }

    // console.log("zeroBalProbs:", zeroBalProbabilities)

    let output = {
      rawOutput: simulatedLives,
      zeroBalProbabilities: {
        ageYears: ageYears,
        zeroBalanceProbabilities: zeroBalProbabilities,
      },
    }
    
    lastUserInput = controlUserInput
    lastOutput = output

    return (output)    
}




export function endTimePop(startTime, message) {
    var endTime = new Date().getTime()
    var time = endTime - startTime
    // console.log(message+" "+time)
}






export function calculateStage1Data(userInput) {

let {desiredDistribution, 
    distributionGrowth, 
    currentIRA, 
    currentTaxed, 
    currentAge,
    jumpOffAge,
    IRAContribution,
    taxedContribution,
    lifeExpectancy,
    inflation,
    investmentStyle} = userInput


    function getRate() {
      let horizon = inflation.rawValue
      return investmentStyle[horizon]["cagr"]
    }

    
  let chartData = {
     xAxis: [] ,
     IRABalances: [],
     TaxedBalances: [],
     totalBalances: [],
     spend: [],
  }

  var runningBal = {
    IRA: currentIRA,
    Taxed:currentTaxed,
  }

  var dataForExport = [["Age","Start IRA balance","IRA growth","IRA Contr","IRA spend","IRA tax","End IRA Bal","Start Taxed balance","Taxed growth","taxed contr","taxed spend", "taxed tax", "End taxed bal"]]

  for (let a = currentAge; a <= lifeExpectancy; a++) {

    let yearsOffset = (a - currentAge)

    let IRAiteration = {
      startBal : runningBal.IRA,
      growth : 0,
      contribution : 0,
      distribution : 0,
      tax : 0,
      endBal : 0
    }

    let taxedIteration = {
      startBal : runningBal.Taxed,
      growth : 0,
      contribution : 0,
      distribution : 0,
      tax : 0,
      endBal : 0
    }

    
    let targetDistribution = desiredDistribution * (1+inflation.value)**yearsOffset
    if (runningBal.IRA + runningBal.Taxed === 0 ) {
      targetDistribution = 0
    }

    if (a >= jumpOffAge) {
    // Post jump-off
      if (taxedIteration.startBal * 1.2 > targetDistribution ) {
      // Draw from taxed account first
        taxedIteration.growth = taxedIteration.startBal * getRate()
        taxedIteration.distribution = targetDistribution
        taxedIteration.tax = getTax(targetDistribution,false)
        taxedIteration.endBal = Math.max(0,(taxedIteration.startBal + taxedIteration.growth - targetDistribution - taxedIteration.tax))
        runningBal.Taxed = taxedIteration.endBal

      // Grow IRA account
        IRAiteration.growth = IRAiteration.startBal * getRate()
        IRAiteration.endBal = IRAiteration.startBal + IRAiteration.growth 
        runningBal.IRA = IRAiteration.endBal

      } else {
      // Draw from IRA account second
        IRAiteration.growth = Math.max(0,(IRAiteration.startBal * getRate()))        
      // if (runningBal.IRA < 1) {desiredDistribution = 0}
        IRAiteration.distribution = targetDistribution
        IRAiteration.tax = getTax(targetDistribution, true)
        IRAiteration.endBal = Math.max(0,(IRAiteration.startBal + IRAiteration.growth - IRAiteration.distribution - IRAiteration.tax))
        runningBal.IRA = IRAiteration.endBal
        
      // grow taxed account
        taxedIteration.growth = Math.max(0,(taxedIteration.startBal * getRate()))
        taxedIteration.endBal = Math.max(0,(taxedIteration.startBal + taxedIteration.growth))
        runningBal.Taxed = taxedIteration.endBal
      }
    } else {  
    //Pre jump-off

      // Grow IRA account
      IRAiteration.growth = IRAiteration.startBal * getRate()
      IRAiteration.contribution = IRAContribution
      IRAiteration.endBal = IRAiteration.startBal + IRAiteration.growth + IRAContribution
      runningBal.IRA = IRAiteration.endBal

      // Grow taxed account
      taxedIteration.growth = taxedIteration.startBal * getRate()
      taxedIteration.contribution = taxedContribution
      taxedIteration.endBal = taxedIteration.startBal + taxedIteration.growth + taxedContribution
      runningBal.Taxed = taxedIteration.endBal
    }

    // console.log("taxed iteration:", taxedIteration)
    // console.log("ira iteration:", IRAiteration)

    chartData.xAxis.push(a)
    chartData.IRABalances.push(runningBal.IRA)
    chartData.TaxedBalances.push(runningBal.Taxed)
    chartData.totalBalances.push(runningBal.IRA + runningBal.Taxed)
    chartData.spend.push(Math.max(IRAiteration.distribution, taxedIteration.distribution))
    
    

    function exportData () {
      let data = [
        a, 
        IRAiteration.startBal,
        IRAiteration.growth, 
        IRAiteration.contribution,
        IRAiteration.distribution,
        IRAiteration.tax,
        IRAiteration.endBal,

        taxedIteration.startBal, 
        taxedIteration.growth, 
        taxedIteration.contribution,
        taxedIteration.distribution,
        taxedIteration.tax,
        taxedIteration.endBal,
        ]
        dataForExport.push(data)
      }
    exportData()
  }      // =========== END OF LOOP ===========


  // console.log(chartData)

  // console.log(dataForExport)
  // exportToCsv("Stage1data.csv", dataForExport)


  let dataForChart = {
      categories: chartData.xAxis,
      series: [
        {
          name: "Assets", 
          data: chartData.totalBalances,
        },
        {
          name: "Spend",
          data: chartData.spend,
        }
      ]
    }

    // console.log(dataForChart)

    return dataForChart
}

import { Grid, Typography } from "@material-ui/core";
import React from "react";
import SimpleCard from "../Cards";
import FormattedInput from "../FormattedInputs";
import { AgeSlider } from "../Sliders";

export const NowCard = (userInput, setUserInput) => {
  const handleIRAInput = (v) => {
    setUserInput({ ...userInput, currentIRA: v });
  };

  const handleTaxedInput = (v) => {
    setUserInput({ ...userInput, currentTaxed: v });
  };

  const nowInputFields = () => {
    let currentIRAInputField = FormattedInput(
      "Retirement bal.",
      handleIRAInput,
      userInput.currentIRA
    );
    let currentTaxedInputField = FormattedInput(
      "After-tax bal.",
      handleTaxedInput,
      userInput.currentTaxed
    );

    return (
      <Grid container>
        <Grid item xs={6}>
          {currentIRAInputField}
        </Grid>
        <Grid item xs={6}>
          {currentTaxedInputField}
        </Grid>
      </Grid>
    );
  };

  const ageInputSlider = () => {
    return (
      <Grid item xs={12} style={{ marginTop: 30, marginLeft: 10 }}>
        <Typography id="ageLabel" gutterBottom>
          Age
        </Typography>
        {AgeSlider(userInput, setUserInput)}
      </Grid>
    );
  };

  let NowCard = SimpleCard(
    "Start with the basics",
    [nowInputFields(), ageInputSlider()],
    "",
    270
  );

  return NowCard;
};

import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import SimpleCard from "../Cards";
import { CustomCallOut } from "../CustomCallOut";
import SimpleSelect from "../Dropdown";
import { InflationRatesV2, PerformanceRatesV2 } from "../InvestmentStyles";

export const MacroConditionsCard = (userInput, setUserInput) => {
  const [localInputs, setLocalInputs] = useState({
    investmentStyle: PerformanceRatesV2.benchmarks.SNP500,
    timeHorizon: InflationRatesV2.last30,
  });

  const handleInvestmentStyleUpdate = (v) => {
    setLocalInputs({ ...localInputs, investmentStyle: v });
    setUserInput({ ...userInput, investmentStyle: v });
  };

  const macroConditionsInputFields = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          {SimpleSelect(
            "Target perf.",
            PerformanceRatesV2.benchmarks,
            localInputs.investmentStyle,
            handleInvestmentStyleUpdate
          )}
        </Grid>
        <Grid item xs={6}>
          {CustomCallOut(localInputs.investmentStyle.prompt)}
        </Grid>
      </Grid>
    );
  };

  let MacroCondCard = SimpleCard(
    "Investment approach",
    [macroConditionsInputFields()],
    "\n Choose the benchmark that most closely represents your investment style. We'll assume that the market will continue to perform more or less as it has performed over the past 30 years",
    270
  );
  // let MacroCondCard = SimpleCard("Performance expectation", [macroConditionsInputFields()], "Choose the benchmarks that are closest to your investment style and time horizon. We're assuming that the markets will continue to perform more or less as they have performed over the past "+localInputs.timeHorizon.label+".", 270)

  return MacroCondCard;
};

import NormalDistribution from "normal-distribution"; // Works for looking up percentiles
import { endTimePop } from "./SimulateMarketPerformance";

var lastUserInput = {};
var returnedOutput = {};

export function summarizeData(simLives, userInput) {
  let { ageInput, lifeExpectancyInput } = userInput;

  if (JSON.stringify(userInput) === JSON.stringify(lastUserInput)) {
    console.log("Cached check");
    return returnedOutput;
  } else {
    console.log("Calculating...");
  }

  let exportDataEntries = [];
  let twentyFiveSeventyFiveData = {
    categories: [],
    series: [
      {
        name: "25%-75% probability",
        data: [],
      },
    ],
  };

  let heatMapData = {
    categories: {
      x: [],
      y: ["10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%"],
    },
    series: [[], [], [], [], [], [], [], [], []],
  };

  var startTime = new Date().getTime();
  for (let a = 0; a <= lifeExpectancyInput - ageInput; a++) {
    let endBalanceByAge = simLives.map(
      (simLife) => simLife[a].ira.endBalance + simLife[a].taxed.endBalance
    );

    let age = ageInput + a;

    if (age % 10 === 0) {
      for (let i = 0; i < heatMapData.categories.y.length; i++) {
        let intYValue = parseInt(heatMapData.categories.y[i], 10);
        let quintile = intYValue / 100;
        let percentile = q(endBalanceByAge, quintile);

        heatMapData.series[i].push(percentile);
      }
      heatMapData.categories.x.push(age.toString());
    }

    let twentyFifthPerc = q25(endBalanceByAge);
    let seventyFithPerc = q75(endBalanceByAge);
    let entry = {
      age: age,
      "25%": twentyFifthPerc,
      "75%": seventyFithPerc,
      rawData: endBalanceByAge,
    };
    exportDataEntries.push(entry);
    twentyFiveSeventyFiveData.categories.push(age);
    twentyFiveSeventyFiveData.series[0].data.push([
      twentyFifthPerc,
      seventyFithPerc,
    ]);
  }

  endTimePop(startTime, "stats run time:");

  // exportToJsonFile(exportDataEntries)
  // console.log(heatMapData)

  let output = {
    twentyFiveSeventyFive: twentyFiveSeventyFiveData,
    heatMapData: heatMapData,
  };

  lastUserInput = userInput;
  returnedOutput = output;

  return output;
}

// https://www.codevoila.com/post/30/export-json-data-to-downloadable-file-using-javascript
export function exportToJsonFile(jsonData) {
  let dataStr = JSON.stringify(jsonData);
  let dataUri =
    "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

  let exportFileDefaultName = "data.json";

  let linkElement = document.createElement("a");
  linkElement.setAttribute("href", dataUri);
  linkElement.setAttribute("download", exportFileDefaultName);
  linkElement.click();
}

export const getHistogramData = (userInput, simulatedLives) => {
  const forAge = userInput.zoomedInAge;
  const ageInput = userInput.currentAge;
  const lifeExpectancy = userInput.lifeExpectancy;
  const zoomAge = Math.min(lifeExpectancy - ageInput, forAge - ageInput);

  let ageBalMap = simulatedLives.map((life) => {
    let iraEndBal = life[zoomAge]["ira"]["endBalance"];
    let taxedEndBal = life[zoomAge]["taxed"]["endBalance"];
    var totalBal = 0;
    if (iraEndBal > 0) {
      totalBal = iraEndBal + taxedEndBal;
    }
    return totalBal;
  });

  let maxBal = Math.min(1000000000, Math.max.apply(Math, ageBalMap));

  const histogramBandsModel = [
    {
      bandStart: 0,
      bandEnd: 1,
      bandLabel: "0",
      count: 0,
    },
  ];

  const histoBands = [
    [0, 1],
    [2, 1000000],
    [1000001, 2000000],
    [2000001, 3000000],
    [3000001, 4000000],
    [4000001, 5000000],
    [5000001, 10000000],
    [10000001, 20000000],
    [20000001, 30000000],
    [30000001, 40000000],
    [40000001, 50000000],
    [50000001, 100000000],
    [100000001, 200000000],
    [200000001, 300000000],
    [300000001, 400000000],
    [400000001, 500000000],
  ];

  const defineHistogramBandsV2 = () => {
    for (let i = 0; i < histoBands.length; i++) {
      const element = histoBands[i];

      let newBand = {
        bandStart: element[0],
        bandEnd: element[1],
        bandLabel: element[0] + "-" + element[1],
        count: 0,
      };
      histogramBandsModel[i] = newBand;
    }
  };
  defineHistogramBandsV2();

  const calculateHistogramData = () => {
    for (let i = 0; i < histogramBandsModel.length; i++) {
      let start = histogramBandsModel[i].bandStart;
      let end = histogramBandsModel[i].bandEnd;

      let filteredAgeBalMap = ageBalMap.filter((x) => x >= start && x < end);
      histogramBandsModel[i].count =
        (filteredAgeBalMap.length / ageBalMap.length) * 100;
    }

    // add the catch all bucket
    let filteredAgeBalMap = ageBalMap.filter(
      (x) => x >= histogramBandsModel[histogramBandsModel.length - 1].bandEnd
    );

    const catchAllBand = {
      bandStart:
        histogramBandsModel[histogramBandsModel.length - 1].bandEnd + 1,
      bandLabel: "above",
      count: (filteredAgeBalMap.length / ageBalMap.length) * 100,
    };
    histogramBandsModel.push(catchAllBand);

    // console.log("----- calculate histogram bands data= ", histogramBandsModel);
    // console.log("----- age bal map = ", ageBalMap)
    // exportToJsonFile(ageBalMap)
  };
  calculateHistogramData();

  return histogramBandsModel;
};

// https://stackoverflow.com/questions/48719873/how-to-get-median-and-quartiles-percentiles-of-an-array-in-javascript-or-php

// sort array ascending
const asc = (arr) => arr.sort((a, b) => a - b);

const sum = (arr) => arr.reduce((a, b) => a + b, 0);

const mean = (arr) => sum(arr) / arr.length;

// sample standard deviation
const std = (arr) => {
  const mu = mean(arr);
  const diffArr = arr.map((a) => (a - mu) ** 2);
  return Math.sqrt(sum(diffArr) / (arr.length - 1));
};

const quantile = (arr, q) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

const q25 = (arr) => quantile(arr, 0.25);

const q50 = (arr) => quantile(arr, 0.5);

const q75 = (arr) => quantile(arr, 0.75);

const q = (arr, percentile) => quantile(arr, percentile);

const median = (arr) => q50(arr);

//https://stackoverflow.com/questions/7343890/standard-deviation-javascript
function getMeanAndStDev(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b) / n;
  const stDev = Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
  return mean, stDev;
}

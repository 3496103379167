import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, IconButton } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "rgb(180, 229, 254)",
    },
  },
  completed: {
    "& $line": {
      borderColor: "rgb(180, 229, 254)",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "rgb(65, 84, 179)",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "rgb(65, 84, 179)",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function CustomizedStepper(userInput, setUserInput, cards) {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);

  const steps = () => {
    var stepsArray = [];
    cards.map((e, i) => {
      stepsArray.push("");
    });
    return stepsArray;
  };

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => {
    //   setUserInput({...userInput, inputStage: prevActiveStep + 1})
    //   return prevActiveStep + 1
    // });

    setUserInput({ ...userInput, inputStage: userInput.inputStage + 1 });
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => {
    //   setUserInput({...userInput, inputStage: prevActiveStep - 1})
    //   return prevActiveStep - 1
    // });
    setUserInput({ ...userInput, inputStage: userInput.inputStage - 1 });
  };

  var stepsToRender = [];

  return (
    <div
      className={classes.root}
      style={{
        display: "flex",
        alignItems: "center",
        height: 60,
        marginTop: -10,
        marginBottom: 20,
        marginLeft: -10,
        marginRight: 0,
        paddingRight: 20,
        backgroundColor: "#ffffff",
      }}
    >
      <Grid container style={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2} lg={1}>
          <IconButton
            aria-label="back"
            disabled={userInput.inputStage === 0}
            onClick={handleBack}
            size="small"
          >
            <KeyboardArrowLeftIcon /> back
          </IconButton>
        </Grid>

        <Grid item xs={8} lg={10}>
          <Stepper
            alternativeLabel
            activeStep={userInput.inputStage}
            connector={<QontoConnector />}
            style={{
              backgroundColor: "rgba(255, 0, 0, 0)",
              padding: 0,
              margin: 0,
            }}
          >
            {steps().map((label, i) => {
              if (i !== cards.length - 1) {
                return (
                  <Step key={i}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                );
              }
            })}
          </Stepper>
        </Grid>

        <Grid
          item
          xs={2}
          lg={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            aria-label="next"
            disabled={userInput.inputStage === steps().length - 1}
            onClick={handleNext}
            size="small"
            style={{}}
          >
            next <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>

      <br />
    </div>
  );
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {PerformanceRates} from './InvestmentStyles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



export default function SimpleSelect(inputLabel, selectionList, selectedItem, setSelection) {
  const classes = useStyles();

  const [currentItem, setCurrentItem] = useState(selectedItem)


  const handleChange = (event) => {
    setSelection(event.target.value)
    setCurrentItem(event.target.value)
  };


  
function menuItems () {

    let menuItems = []
    for (const element in selectionList) {
        let item =  <MenuItem value={selectionList[element]}>{selectionList[element]["label"]}</MenuItem> 
        menuItems.push(item)
    }
    return menuItems
}



  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentItem}
          onChange={handleChange}
        >
            {menuItems()}
        </Select>
      </FormControl>


    </div>
  );
}

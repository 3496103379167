import {
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { useState } from "react";
import { GAevents, logGAEvent } from "./Firebase";
import { GetUserIPData } from "./Utilities";

// import { LinkedinIcon, TwitterIcon } from "react-share";

export const Footer = () => {
  const [linkJustCopied, setLinkJustCopied] = useState(false);

  const handleCopyClick = () => {
    var text = "www.finculator.app";
    navigator.clipboard.writeText(text).then(
      function() {
        setLinkJustCopied(true);
        var delayInMilliseconds = 2000; //1 second
        setTimeout(function() {
          setLinkJustCopied(false);
        }, delayInMilliseconds);
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <Container style={{ textAlign: "center", marginBottom: 30, marginTop: 30 }}>
      <Typography variant="subtitle2">Stay in touch</Typography>
      <br />
      <TwitterIcon
        fontSize="large"
        style={{ marginRight: 20 }}
        onClick={() => (
          logGAEvent(GAevents.twitterProfileClicked),
          (window.location.href = "https://twitter.com/finculator")
        )}
      />
      <LinkedInIcon
        fontSize="large"
        onClick={() => (
          logGAEvent(GAevents.linkedInProfileClicked),
          (window.location.href = "https://www.linkedin.com/company/finculator")
        )}
      />
      {/* <IconButton onClick={handleCopyClick}>
        <LinkIcon />
      </IconButton> */}
      <br />
      <br />
      <Typography variant="subtitle2">finculator.app</Typography>
    </Container>
  );
};

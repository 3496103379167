import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import FormattedInput from "../FormattedInputs";
import SimpleCard from "../Cards";

export const WhileYouWorkCard = (userInput, setUserInput) => {
  const handleRetirementInput = (v) => {
    setUserInput({ ...userInput, IRAContribution: v });
  };

  const handleTaxedInput = (v) => {
    setUserInput({ ...userInput, taxedContribution: v });
  };

  const SavingsInputFields = () => {
    let IRASavingsInput = FormattedInput(
      "Retrmt savings",
      handleRetirementInput,
      userInput.IRAContribution
    );
    let TaxedSavingsInput = FormattedInput(
      "After-tax savings",
      handleTaxedInput,
      userInput.taxedContribution
    );

    return (
      <Grid container>
        <Grid item xs={6}>
          {IRASavingsInput}
        </Grid>
        <Grid item xs={6}>
          {TaxedSavingsInput}
        </Grid>
      </Grid>
    );
  };

  const WhileYouWorkCard = SimpleCard(
    "Savings while you work",
    [SavingsInputFields()],
    "Enter amounts you are planning to save each year throughout your working life. Assumes no increases in savings amounts.",
    270
  );

  return WhileYouWorkCard;
};

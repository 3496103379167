import { Grid } from "@material-ui/core";
import { AreaChart } from "@toast-ui/react-chart";
import SimpleCard from "../Cards";
import { simulateMarketPerf } from "../SimulateMarketPerformance";
import { ValueFormatter } from "../Utilities";

export const ZeroBalProbabilitiesChart = (userInput, setUserInput) => {
  let chartData = simulateMarketPerf(
    userInput,
    3000,
    "triggered from RenderZeroProb.... "
  );

  let { ageYears, zeroBalanceProbabilities } = chartData[
    "zeroBalProbabilities"
  ];

  var counter = 1;

  const options = {
    tooltip: {
      formatter: (v, extra) => {
        return ValueFormatter(v) + "%";
      },
    },
    chart: {
      width: "100%",
      height: 350,
      // title: 'Asset balance and distributions',
    },
    yAxis: {
      title: "%",
      scale: {
        max: 100,
        min: 0,
        stepSize: 10,
      },
    },
    xAxis: {
      title: "Age",
      offsetX: 200,
      label: {
        interval: 10,
      },
      tick: {
        interval: 10,
      },
    },
    legend: {
      visible: false,
    },
    exportMenu: {
      visible: false,
    },
    series: {
      dataLabels: {
        visible: true,
        offsetY: 10,
        interval: 10,
        formatter: (value) => {
          let response = 1;
          if (counter === 10) {
            counter = 1;
            response = parseFloat(value).toFixed(0) + "%";
          } else {
            counter += 1;
            response = "";
          }
          return response;
        },
      },
    },
    plot: {
      visible: false,
      vertical: {
        lineWidth: 0,
      },
    },
  };

  let data = {
    categories: ageYears,
    series: [
      {
        name: "Likelihood",
        data: zeroBalanceProbabilities,
      },
    ],
  };

  let chart = <AreaChart data={data} options={options} />;
  let card = SimpleCard(
    "Likelihood to outlive your money",
    [chart],
    "Investments perform unpredictably. There's no way to know precisely how your assets will do in the long run. Instead, we're estimating the likelihood of different outcomes by extrapolating past market performance into the future."
  );

  return (
    <Grid item xs={12}>
      {card}
    </Grid>
  );
};

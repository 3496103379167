import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/database";
import "firebase/auth";
import { GetDateString, GetTimeString } from "./Utilities";

var firebaseConfig = {
  apiKey: "AIzaSyCzD8p4CiZYd4rNnH7w3prVUPY3CyJlYmE",
  authDomain: "finculator.firebaseapp.com",
  projectId: "finculator",
  storageBucket: "finculator.appspot.com",
  messagingSenderId: "16274726697",
  appId: "1:16274726697:web:3fd9a90839454ab087e138",
  measurementId: "G-LRHF4N3Z4E",
};

export const SetUpFirebase = (userInput, setUserInput) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    firebase
      .auth()
      .signInAnonymously()
      .then((object) => {
        // Signed in..
        setUserInput({
          ...userInput,
          userId: object.user.uid,
        });
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("error signing in -0s--=s=-s", errorMessage);
      });
  }
};

var lastUIstage = 0;
export const WriteUserDataToFirebase = (userInput) => {
  const setUserInputStage = () => {
    if (userInput.inputStage > lastUIstage) {
      lastUIstage = userInput.inputStage;
    }
    return lastUIstage;
  };

  firebase
    .database()
    .ref(
      "usersByDate/" +
        GetDateString() +
        "/" +
        (firebase.auth().currentUser
          ? firebase.auth().currentUser.uid
          : "unAuthenticated")
    )
    .set({
      dateString: Date(),
      currentIRA: userInput.currentIRA,
      currentTaxed: userInput.currentTaxed,
      currentAge: userInput.currentAge,
      jumpOffAge: userInput.jumpOffAge,
      IRAContribution: userInput.IRAContribution,
      taxedContribution: userInput.taxedContribution,
      lifeExpectancy: userInput.lifeExpectancy,
      desiredDistribution: userInput.desiredDistribution,
      distributionGrowth: userInput.distributionGrowth,
      investmentStyle: userInput.investmentStyle.label,
      zoomedInAge: userInput.zoomedInAge,
      inputStage: setUserInputStage(),
      userLocation: userInput.userLocation,
    });

  firebase
    .database()
    .ref(
      "usersAll/" +
        (firebase.auth().currentUser
          ? firebase.auth().currentUser.uid
          : "unAuthenticated")
    )
    .set({
      dateString: Date(),
      currentIRA: userInput.currentIRA,
      currentTaxed: userInput.currentTaxed,
      currentAge: userInput.currentAge,
      jumpOffAge: userInput.jumpOffAge,
      IRAContribution: userInput.IRAContribution,
      taxedContribution: userInput.taxedContribution,
      lifeExpectancy: userInput.lifeExpectancy,
      desiredDistribution: userInput.desiredDistribution,
      distributionGrowth: userInput.distributionGrowth,
      investmentStyle: userInput.investmentStyle.label,
      zoomedInAge: userInput.zoomedInAge,
      inputStage: setUserInputStage(),
      userLocation: userInput.userLocation,
    });
};

export const RecordFeedbackPoints = (feedbackPoint) => {
  firebase
    .database()
    .ref(
      "feedback/ratings/" +
        GetDateString() +
        "/" +
        firebase.auth().currentUser.uid +
        "/"
    )
    .set({ rating: feedbackPoint });
};

export const RecordFeedbackVerbatim = (verbatim) => {
  firebase
    .database()
    .ref(
      "feedback/verbatims/" +
        GetDateString() +
        "/" +
        firebase.auth().currentUser.uid +
        "/" +
        GetTimeString() +
        "/"
    )
    .set({ verbatim: verbatim });
};

export const logGAEvent = (event) => {
  firebase.analytics().logEvent(event);
};

export const GAevents = {
  linkedInProfileClicked: "LinkedInProfileClicked",
  twitterProfileClicked: "TwitterProfileClicked",
};

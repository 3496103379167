import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid, Container } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 1300,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});





  


export default function SimpleCard(title, content, subtitle, height) {
  const classes = useStyles();

    const subtitleJSX = () => {
      if (subtitle !== undefined) {
      return <>
            <br/>
            <Typography variant="caption" style={{lineHeight: 0}}>
              {subtitle}
            </Typography>
            </>
      }
    }
  


  return (
      <Grid item xs={12} style={{marginBottom: 20}}>
          <Container>
            <Card className={classes.root} style={{height:height}}>
                <CardContent>
                    
                    <Typography variant="h6" component="h6" style={{marginBottom:10}}>
                    {title}
                    </Typography>

                    {content.map((element, i) =>
                        <span key={i}>
                            {element}
                        </span>
                    )}
                    
                    {subtitleJSX()}
                </CardContent>
                    {/* <CardActions>
                        <Button size="small" >Details</Button>
                    </CardActions> */}
                </Card>
            </Container>
      </Grid>
  );
}

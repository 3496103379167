import SimpleCard from "../Cards";
import { Grid, Typography } from "@material-ui/core";
import { getHistogramData } from "../RunStats";
import { simulateMarketPerf } from "../SimulateMarketPerformance";
import { ZoomInAgeSlider } from "../Sliders";
import { ValueFormatter } from "../Utilities";
import { BarChart } from "@toast-ui/react-chart";

export const HistogramBarChart = (userInput, setUserInput) => {
  const options = {
    chart: {
      width: "100%",
      height: 450,
    },
    tooltip: {
      formatter: (v) => {
        return ValueFormatter(v) + "%";
      },
    },
    series: {
      dataLabels: {
        visible: true,
        formatter: (v) => {
          return ValueFormatter(v);
        },
      },
    },
    yAxis: {
      title: "$ balance",
      label: {
        margin: 0,
        formatter: (v, x) => {
          if (x.index === 0) {
            return "super rich";
          }
          if (x.index === x.labels.length - 1) {
            return "broke 😞";
          }

          return `${ValueFormatter(v)}-${ValueFormatter(
            x.labels[x.index - 1]
          )}`;
        },
      },
    },
    xAxis: {
      title: "% likelihood",
      label: {
        formatter: (v) => {
          return `${v}%`;
        },
      },
    },
    legend: {
      visible: false,
    },
    exportMenu: {
      visible: false,
    },
    plot: {
      visible: false,
    },
  };

  let chartData = simulateMarketPerf(
    userInput,
    4000,
    "rendered from Histogram Bar Chart"
  );
  let histogramData = getHistogramData(userInput, chartData.rawOutput);
  let histoBands = histogramData.map((e) => e.bandStart);
  let histoData = histogramData.map((e) => e.count);

  let data = {
    categories: histoBands.reverse(),
    series: [
      {
        name: "Likelihood",
        data: histoData.reverse(),
      },
    ],
  };

  // console.log("bar chart data: ", data)

  let slider = ZoomInAgeSlider(userInput, setUserInput);
  let chart = <BarChart data={data} options={options} />;
  let layout = (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2">
          Move the slider to see the asset balance probabilities at different
          ages.
        </Typography>
        <br />
      </Grid>
      <Grid item xs={12}>
        <br />
        <br />
        {slider}
      </Grid>
      <Grid item xs={12}>
        {chart}
      </Grid>
    </Grid>
  );
  let card = SimpleCard(
    "Likelihood of balances at age " + userInput.zoomedInAge,
    [layout]
  );

  return card;
};


let incomeTaxBracket = [
    [19750, 0.1] , 
    [80250, 0.12] ,
    [171050, 0.22] ,
    [326600, 0.24] ,
    [414700, 0.32] ,
    [622050, 0.35] ,
    [9999999999, 0.37]        
]

let longTermGainTaxBrackets = [
    [80000, 0],
    [496600, 0.15],
    [999999999999, 0.2]
]



export default function getTax(income, isIRA) {
    let remainingBalance = income

    if (isIRA) {                            // Validated this logic branch with spot chacking. 
        var taxAmount = 0
        for (let i = 0; i < incomeTaxBracket.length; i++) {
            if (remainingBalance <= 0) { break }

            if (i===0) {
                let upperBound = incomeTaxBracket[0][0] 
                let rate = incomeTaxBracket[0][1]
                if (remainingBalance > upperBound) {
                    taxAmount = upperBound * rate
                } else {
                    taxAmount = remainingBalance * rate
                }
                remainingBalance = Math.max(0, remainingBalance - upperBound)
            } else {
                let currentUpperBound = incomeTaxBracket[i][0]
                let prevUpperBound = incomeTaxBracket[(i-1)][0]
                let incrAmount = currentUpperBound - prevUpperBound
                
                let rate = incomeTaxBracket[i][1]

                let tax = remainingBalance * rate
                if (remainingBalance > incrAmount) {
                    tax = incrAmount * rate
                } 
                
                taxAmount = taxAmount + tax
                remainingBalance = Math.max(0, remainingBalance - incrAmount)
            }
        }
        return taxAmount
    } else {                            // Reviewed code but not validated
        var taxAmount = 0
        for (let i = 0; i < longTermGainTaxBrackets.length; i++) {
            let upperBound = longTermGainTaxBrackets[i][0] 
            let rate = longTermGainTaxBrackets[i][1]

            let tax = 0

            if (i === 0)  {
                tax = remainingBalance * rate
                if (remainingBalance > upperBound) {
                    tax = upperBound * rate
                } 
                remainingBalance = Math.max(0, remainingBalance-upperBound)
            } else {
                let prevUpperBound = longTermGainTaxBrackets[i-1][0]
                let incrAmount = upperBound - prevUpperBound
                tax = remainingBalance * rate
                if (remainingBalance > incrAmount) {
                    tax = incrAmount * rate
                }
                remainingBalance = Math.max(0, remainingBalance-incrAmount)
            }
            taxAmount += tax
        }
        return taxAmount
    }
}
import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { GetTimeString } from "./Utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormattedInput(label, valueDidChange, defVal) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    numberformat: "",
  });

  const handleChange = (event) => {
    valueDidChange(Number(event.target.value));
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const timestamp = new Date().getUTCMilliseconds() + Math.random() + "";

  return (
    <div className={classes.root}>
      <TextField
        label={label}
        // value={values.numberformat}
        value={defVal}
        onChange={handleChange}
        // defaultValue={defVal}
        name="numberformat"
        // id="formatted-numberformat-input"
        id={timestamp}
        margin="dense"
        // key={timestamp}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>
  );
}

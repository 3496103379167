import { React, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Container, Grid, IconButton, TextField } from "@material-ui/core";
import { RecordFeedbackPoints, RecordFeedbackVerbatim } from "./Firebase";
import SendIcon from "@material-ui/icons/Send";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ff6d75",
  },
  iconHover: {
    color: "#ff3d47",
  },
})(Rating);

export default function CustomizedRatings() {
  const [feedbackVariables, setfeedbackVariables] = useState({
    feedbackSent: false,
    userRating: 0,
    feedbackString: "",
  });

  const handleRatingChange = (e) => {
    RecordFeedbackPoints(Number(e.target.value));
    setfeedbackVariables({
      ...feedbackVariables,
      userRating: Number(e.target.value),
    });
  };

  return (
    <Container style={{ textAlign: "center" }}>
      <Box component="fieldset" mb={3} borderColor="transparent">
        <Typography component="legend">
          {legendText(feedbackVariables)}
        </Typography>
        <StyledRating
          name="customized-color"
          defaultValue={0}
          getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
          precision={1}
          onChange={handleRatingChange}
          icon={<FavoriteIcon fontSize="inherit" />}
        />
      </Box>

      {postFeedbackDialog(feedbackVariables, setfeedbackVariables)}

      <br />
    </Container>
  );
}

const postFeedbackDialog = (feedbackVariables, setfeedbackVariables) => {
  const { userRating } = feedbackVariables;

  if (userRating === 0) {
    return null;
  }
  if (userRating > 0 && userRating < 4) {
    return FeedbackComponent(feedbackVariables, setfeedbackVariables);
  }
  if (userRating > 3) {
    return shareComponent();
  }
};

//
//

const FeedbackComponent = (feedbackVariables, setfeedbackVariables) => {
  //
  const handleUpdate = (e) => {
    setfeedbackVariables({
      ...feedbackVariables,
      feedbackString: e.target.value,
    });
  };

  const handleSendClick = () => {
    RecordFeedbackVerbatim(feedbackVariables.feedbackString);
    setfeedbackVariables({
      ...feedbackVariables,
      feedbackString: "",
      feedbackSent: true,
    });
  };

  const { feedbackString, feedbackSent } = feedbackVariables;

  const textFeedbackComponent = (
    <Grid container>
      <Grid item xs={10}>
        <TextField
          placeholder={
            feedbackSent ? "Thanks for the feedback!" : "How can we improve?"
          }
          value={feedbackString}
          onChange={handleUpdate}
          style={{ textAlign: "center", width: "100%" }}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton onClick={handleSendClick}>
          <SendIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
  return textFeedbackComponent;
};

const shareComponent = () => {
  return (
    <Container>
      <Typography variant="subtitle2">
        Glad you found it useful. Consider sharing the love with friends.
      </Typography>
      <br />
      <EmailShareButton
        subject="You might find this useful"
        body="I came across this app online and thought it might interest you."
        url="www.finculator.app"
        style={{ paddingRight: 10 }}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>

      <FacebookShareButton
        url="www.finculator.app"
        quote="How soon can you retire?"
        hashtag="#financialfreedom"
        style={{ paddingRight: 10 }}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <RedditShareButton
        url="www.finculator.app"
        title="How long do you have to keep working?"
        style={{ paddingRight: 10 }}
      >
        <RedditIcon size={32} round />
      </RedditShareButton>

      <TwitterShareButton
        url="www.finculator.app"
        title="How long do you have to keep working?"
        style={{ paddingRight: 10 }}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </Container>
  );
};

const legendText = (feedbackVariables) => {
  const { userRating } = feedbackVariables;

  if (userRating === 0) {
    return <Typography variant="subtitle2">We ♡ feedback</Typography>;
  }

  if (userRating === 1 || userRating === 2 || userRating === 3) {
    return (
      <Typography variant="subtitle2">
        Help us improve with feedback{" "}
      </Typography>
    );
  }

  if (userRating === 4 || userRating === 5) {
    return <Typography variant="subtitle2"> Yay! Thanks! </Typography>;
  }
};

//
// Social share buttons
//
// https://www.npmjs.com/package/react-share

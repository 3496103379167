import { Grid, TextField, Typography } from "@material-ui/core";
import SimpleCard from "../Cards";
import React, { useState } from "react";
import FormattedInput from "../FormattedInputs";
import { JumpOffAgeSlider } from "../Sliders";

export const PostRetirementCard = (userInput, setUserInput) => {
  const [localLifeExpValue, setlocalLifeExpValue] = useState(
    userInput.lifeExpectancy
  );
  const [errorText, setErrorText] = useState("");

  const handleDistributionInput = (v) => {
    setUserInput({ ...userInput, desiredDistribution: v });
  };

  const handleLifeExpectancyChange = (e) => {
    const updateAppState = () => {
      setUserInput({ ...userInput, zoomedInAge: e.target.value });
      setUserInput({ ...userInput, lifeExpectancy: e.target.value });
    };

    setlocalLifeExpValue(e.target.value);

    if (isNaN(e.target.value)) {
      setErrorText("not a number");
    } else if (e.target.value <= userInput.jumpOffAge) {
      setErrorText("less than your age");
    } else {
      setErrorText("");
      updateAppState();
    }
  };

  const error = true;

  let distributionInputField = FormattedInput(
    "Desired spend/yr",
    handleDistributionInput,
    userInput.desiredDistribution
  );
  let lifeExpectancyInputField = (
    <TextField
      id="standard-basic"
      label="Life expectancy"
      value={localLifeExpValue}
      onChange={handleLifeExpectancyChange}
    />
  );
  let lifeExpectancyInputFieldError = (
    <TextField
      error
      helperText={errorText}
      id="standard-basic"
      label="Life expectancy"
      value={localLifeExpValue}
      onChange={handleLifeExpectancyChange}
    />
  );

  const laterInputFields = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          {distributionInputField}
        </Grid>
        <Grid item xs={6} style={{ padding: "4px 10px 0px 10px" }}>
          {errorText === ""
            ? lifeExpectancyInputField
            : lifeExpectancyInputFieldError}
        </Grid>
      </Grid>
    );
  };

  const jumpOffAgeSlider = () => {
    return (
      <Grid item xs={12} style={{ marginTop: 30, marginLeft: 10 }}>
        <Typography id="jumpOffLabel" gutterBottom>
          Retirement age
        </Typography>
        {JumpOffAgeSlider(userInput, setUserInput)}
      </Grid>
    );
  };

  const card = SimpleCard(
    "Life after work",
    [laterInputFields(), jumpOffAgeSlider()],
    "",
    270
  );

  return card;
};
